import { useEffect, useState } from "react";
import "./Header.scss";
import useIsMobile from "../hooks/useIsMobile";

export default function Header() {
  const [isAboutUsDropdownOpen, setIsAboutUsDropdownOpen] = useState<boolean>(false);
  const [isFindUsDropdownOpen, setIsFindUsDropdownOpen] = useState<boolean>(false);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isMobile) {
      setIsMenuOpen(true);
      setIsAboutUsDropdownOpen(false);
      setIsFindUsDropdownOpen(false);
    } else {
      setIsMenuOpen(false);
      setIsAboutUsDropdownOpen(false);
      setIsFindUsDropdownOpen(false);
    }
  }, [isMobile]);

  return (
    <div className="Header">
      <div className={`content ${isMobile && isMenuOpen ? "content-expanded" : ""}`}>
        <div className="head-section">
          <img className="uxd-logo" src="icon-transparent.svg" alt="logo" />

          {isMobile ? (
            <>
              <img
                className="white-cross-icon"
                src="white-cross.svg"
                alt="Open hero menu icon"
                onClick={() => {
                  setIsMenuOpen(false);
                  setIsAboutUsDropdownOpen(false);
                  setIsFindUsDropdownOpen(false);
                }}
                style={{
                  display: !isMenuOpen ? "none" : "block",
                }}
              />

              <img
                className={"hero-icon"}
                src="hero-menu-icon.svg"
                alt="Closed hero menu icon"
                onClick={() => {
                  setIsMenuOpen(true);
                }}
                style={{
                  display: isMenuOpen ? "none" : "block",
                }}
              />
            </>
          ) : null}
        </div>

        <div className={`links-section ${isMobile ? (isMenuOpen ? "expand-animation" : "collapse-animation") : ""}`}>
          {isMenuOpen ? (
            <>
              <div className="about_us">
                <div
                  className="title"
                  onClick={() => {
                    setIsAboutUsDropdownOpen(!isAboutUsDropdownOpen);
                    setIsFindUsDropdownOpen(false);
                  }}
                >
                  {isMobile ? <img src="mobile-chevron-menu-close.svg" alt="chevron down icon" className="icon" /> : null}
                  <span className="text">About Us</span>
                  {!isMobile ? <img src="chevron_down.svg" alt="chevron down icon" className="icon" /> : null}
                </div>

                {isAboutUsDropdownOpen ? (
                  <div className="dropdown">
                    <a href="https://uxdprotocol.medium.com/" target="_blank" rel="noreferrer" className="link" onClick={() => setIsAboutUsDropdownOpen(false)}>
                      Medium
                    </a>
                    <a href="https://github.com/uxdprotocol" target="_blank" rel="noreferrer" className="link" onClick={() => setIsAboutUsDropdownOpen(false)}>
                      GitHub
                    </a>
                    <a href="https://docs.uxd.fi/uxdprotocol/" target="_blank" rel="noreferrer" className="link" onClick={() => setIsAboutUsDropdownOpen(false)}>
                      GitBook
                    </a>
                    <a href="https://app.realms.today/dao/UXP" target="_blank" rel="noreferrer" className="link" onClick={() => setIsAboutUsDropdownOpen(false)}>
                      Governance
                    </a>
                  </div>
                ) : null}
              </div>

              <div className="find_us">
                <div
                  className="title"
                  onClick={() => {
                    setIsAboutUsDropdownOpen(false);
                    setIsFindUsDropdownOpen(!isFindUsDropdownOpen);
                  }}
                >
                  {isMobile ? <img src="mobile-chevron-menu-close.svg" alt="chevron down icon" className="icon" /> : null}
                  <span className="text">Find Us</span>
                  {!isMobile ? <img src="chevron_down.svg" alt="chevron down icon" className="icon" /> : null}
                </div>

                {isFindUsDropdownOpen ? (
                  <div className="dropdown">
                    <a href="https://discord.com/invite/BHfpYmjsBM" target="_blank" rel="noreferrer" className="link" onClick={() => setIsFindUsDropdownOpen(false)}>
                      Discord
                    </a>
                    <a href="https://twitter.com/UXDProtocol" target="_blank" rel="noreferrer" className="link" onClick={() => setIsFindUsDropdownOpen(false)}>
                      Twitter
                    </a>
                    <a href="https://www.coingecko.com/en/coins/uxd-stablecoin" target="_blank" rel="noreferrer" className="link" onClick={() => setIsFindUsDropdownOpen(false)}>
                      UXD on CoinGecko
                    </a>
                    <a
                      href="https://www.coingecko.com/en/coins/uxd-protocol-token"
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                      onClick={() => setIsFindUsDropdownOpen(false)}
                    >
                      UXP on CoinGecko
                    </a>
                  </div>
                ) : null}
              </div>

              <div className="webapp">
                {isMobile ? <span className="open-text">:// OPEN</span> : null}

                <a className="webapp-btn" href="https://app.uxd.fi/" target="_blank" rel="noreferrer">
                  Web App
                </a>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
