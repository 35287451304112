import { useCallback, useEffect, useState } from "react";

const useScroll = () => {
  const [y, setY] = useState(window?.scrollY ?? 0);

  const handleNavigation = useCallback((e: any) => {
    const window = e.currentTarget;

    setY(window.scrollY);
  }, []);

  useEffect(() => {
    setY(window.scrollY);

    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return y;
};

export default useScroll;
