import uxdCrossedImg from "../assets/images/UXD-crossed.svg";
import uxpCrossedImg from "../assets/images/UXP-crossed.svg";
import useIsMobile from "../hooks/useIsMobile";

import "./Part1.scss";

export default function Part1({ className }: { className?: string }) {
  const isMobile = useIsMobile();

  return (
    <div className={`Part1 ${className}`}>
      <div className="left">
        <div className="title">UXD PROTOCOL</div>
        <div className="description">
          <span>Decentralized stablecoin stabilized by an</span>
          <div className="asset-liability-management-module-text">
            <div>[ asset liability management module ]</div>
          </div>
        </div>
      </div>

      <div className="right">
        {isMobile ? (
          <>
            <div className="bloc bloc-mobile">
              <div className="col_1">
                <img className="title" src={uxdCrossedImg} alt="logo" />
                <div>Stablecoin</div>
                <div>ステーブルコイン·</div>

                <div className="text">
                  UXD is backed by various low volatility strategies that generate yield. The function of the protocol is to manage the asset and liability side to ensure that
                  there is no mismatch. Examples of low volatility strategies are delta-neutral position, overcollateralized crypto backed loans, liquid and semi-liquid lending,
                  etc.
                </div>
              </div>

              <div className="col_2">
                <div className="links">
                  <a
                    className="arrow_link"
                    style={{
                      marginLeft: "auto",
                    }}
                    href="https://www.coingecko.com/en/coins/uxd-stablecoin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="arrow-up-right.svg" alt="arrow icon" />
                    <span>CoinGecko</span>
                  </a>
                </div>

                <img className="icon" src="uxd-icon-white.svg" alt="logo" />
              </div>
            </div>

            <div className="bloc bloc-mobile">
              <div className="col_1">
                <img className="title" src={uxpCrossedImg} alt="logo" />
                <div>Governance</div>
                <div>ガバナンス·</div>

                <div className="text">
                  The governance token for UXD Protocol. UXP is a productive asset that will receive the cash flows generated from the assets controlled by the protocol. UXP also
                  serves as the final backstop asset of the protocol, in the case the insurance fund is drained.
                </div>
              </div>

              <div className="col_2">
                <div className="links">
                  <a className="arrow_link" href="https://www.coingecko.com/en/coins/uxd-protocol-token" target="_blank" rel="noreferrer">
                    <img src="arrow-up-right.svg" alt="arrow icon" />
                    <span>CoinGecko</span>
                  </a>

                  <a className="arrow_link" href="https://jup.ag/swap/UXP-USDC" target="_blank" rel="noreferrer">
                    <img src="arrow-up-right.svg" alt="arrow icon" />
                    <span>Buy UXP</span>
                  </a>

                  <a className="arrow_link" href="https://stake.uxd.fi/" target="_blank" rel="noreferrer">
                    <img src="arrow-up-right.svg" alt="arrow icon" />
                    <span>Stake UXP</span>
                  </a>
                </div>

                <img src="uxp-icon-white.png" alt="logo" className="icon" />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="bloc bloc-regular">
              <div className="col_1">
                <img className="title" src={uxdCrossedImg} alt="logo" />
                <div>Stablecoin</div>
                <div>ステーブルコイン·</div>
                <img className="icon" src="uxd-icon-white.svg" alt="logo" />
              </div>

              <div className="col_2">
                <div className="links">
                  <a
                    className="arrow_link"
                    style={{
                      marginLeft: "auto",
                    }}
                    href="https://www.coingecko.com/en/coins/uxd-stablecoin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="arrow-up-right.svg" alt="arrow icon" />
                    <span>CoinGecko</span>
                  </a>
                </div>

                <div className="text">
                  UXD is backed by various low volatility strategies that generate yield. The function of the protocol is to manage the asset and liability side to ensure that
                  there is no mismatch. Examples of low volatility strategies are delta-neutral position, overcollateralized crypto backed loans, liquid and semi-liquid lending,
                  etc.
                </div>
              </div>
            </div>

            <div className="bloc bloc-regular">
              <div className="col_1">
                <img className="title" src={uxpCrossedImg} alt="logo" />
                <div>Governance</div>
                <div>ガバナンス·</div>
                <img src="uxp-icon-white.png" alt="logo" className="icon" />
              </div>

              <div className="col_2">
                <div className="links">
                  <a className="arrow_link" href="https://www.coingecko.com/en/coins/uxd-protocol-token" target="_blank" rel="noreferrer">
                    <img src="arrow-up-right.svg" alt="arrow icon" />
                    <span>CoinGecko</span>
                  </a>

                  <a className="arrow_link" href="https://jup.ag/swap/UXP-USDC" target="_blank" rel="noreferrer">
                    <img src="arrow-up-right.svg" alt="arrow icon" />
                    <span>Buy UXP</span>
                  </a>

                  <a className="arrow_link" href="https://stake.uxd.fi/" target="_blank" rel="noreferrer">
                    <img src="arrow-up-right.svg" alt="arrow icon" />
                    <span>Stake UXP</span>
                  </a>
                </div>

                <div className="text">
                  The governance token for UXD Protocol. UXP is a productive asset that will receive the cash flows generated from the assets controlled by the protocol. UXP also
                  serves as the final backstop asset of the protocol, in the case the insurance fund is drained.
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="footer">
        <div>
          <span>LIVE ON</span>
          <img src="solana-icon.svg" alt="Solana icon" />
          <img src="arbitrum-icon.svg" alt="Arbitrum icon" />
        </div>
        <div>
          <span>COMING SOON</span>
          <img src="optimism-icon.svg" className="optimism-icon" alt="Optimism icon" />
        </div>
      </div>
    </div>
  );
}
