import "./Part3.scss";

import UxdCrossedImg from "../assets/images/cross-uxd.svg";
import CylinderImg from "../assets/images/Cylinder.png";
import DashboardImg from "../assets/images/dashboard.png";

export default function Part3({ className }: { className?: string }) {
  return (
    <div className={`Part3 ${className}`}>
      <span className="japanese">· 保険基金 ·</span>
      <div className="title">INSURANCE FUND</div>

      <div className="text">
        The insurance fund represents the equity part of the balance sheet. In a scenario where the asset side incurs losses, the insurance fund will cover the loss.
      </div>

      <div className="dashboard-container">
        <div className="dashboard">
          <img src={CylinderImg} alt="cylinder" className="cylinder" />

          <div className="dashboard-header">
            <span className="title">$/agent/security/uxd.dashboard.log</span>

            <div className="cross">
              <img src="cross-icon.svg" alt="cross icon" />
            </div>
          </div>

          <div className="dashboard-body">
            <img className="icon" src={UxdCrossedImg} alt="uxd crossed icon" />

            <img className="dashboard-img" src={DashboardImg} alt="dashboard" />

            <a className="dashboard-link" href="https://dashboard.uxd.fi/" target="_blank" rel="noreferrer">
              View Dashboard <img src="arrow-up-right-blue.svg" alt="arrow icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
