import Header from "./header/Header";
import Part1 from "./part1/Part1";
import Part2 from "./part2/Part2";
import Part3 from "./part3/Part3";
import Part4 from "./part4/Part4";
import Part5 from "./part5/Part5";
import Part6 from "./part6/Part6";

import "./App.scss";
import useWindowSize from "./hooks/useWindowSize";

function App() {
  const { width } = useWindowSize();

  return (
    <div className="App">
      {width && width > 700 ? (
        <>
          <div className="video-overlay"></div>

          <video autoPlay loop muted className="video">
            <source src="https://uxdprotocol.s3.eu-west-2.amazonaws.com/background.mp4" type="video/mp4" />
          </video>
        </>
      ) : (
        <div className="mobile-background"></div>
      )}

      <div className="inner">
        <Header />

        <Part1 className="part1" />
        <Part2 className="part2" />
        <Part3 className="part3" />
        <Part4 className="part4" />
        <Part5 className="part5" />
        <Part6 className="part6" />
      </div>
    </div>
  );
}

export default App;
