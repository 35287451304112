import React from "react";
import useWindowSize from "./useWindowSize";

const useIsMobile = () => {
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (width && width <= 650) {
      setIsMobile(true);
      return;
    }

    setIsMobile(false);
  }, [width]);

  return isMobile;
};

export default useIsMobile;
