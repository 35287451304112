import streamflowImg from "../assets/images/streamflow.png";

import "./Part6.scss";

export default function Part6({ className }: { className?: string }) {
  return (
    <div className={`Part6 ${className}`}>
      <div className="body">
        <div className="diamond1">
          <span className="diamond"></span>
        </div>

        <div className="diamond2">
          <span className="diamond"></span>
        </div>

        <div className="diamond3">
          <span className="diamond"></span>
        </div>

        <div className="diamond4">
          <span className="diamond"></span>
        </div>

        <div className="uxd-presentation">
          <img src="uxd-fullname.svg" alt="uxd full name icon" />

          <div>DECENTRALIZED - STABLE - CAPITAL EFFICIENT - TRANSPARENT - PRODUCTIVE</div>
        </div>

        <div className="links">
          <div className="about-us">
            <div className="title">ABOUT US</div>
            <a href="https://uxdprotocol.medium.com/" target="_blank" rel="noreferrer" className="link">
              Medium
            </a>
            <a href="https://github.com/uxdprotocol" target="_blank" rel="noreferrer" className="link">
              GitHub
            </a>
            <a href="https://docs.uxd.fi/uxdprotocol/" target="_blank" rel="noreferrer" className="link">
              GitBook
            </a>
            <a href="https://app.realms.today/dao/UXP" target="_blank" rel="noreferrer" className="link">
              Governance
            </a>
          </div>

          <div className="find-us">
            <div className="title">FIND US</div>
            <a href="https://discord.com/invite/BHfpYmjsBM" target="_blank" rel="noreferrer" className="link">
              Discord
            </a>
            <a href="https://twitter.com/UXDProtocol" target="_blank" rel="noreferrer" className="link">
              Twitter
            </a>
            <a href="https://www.coingecko.com/en/coins/uxd-stablecoin" target="_blank" rel="noreferrer" className="link">
              UXD on CoinGecko
            </a>
            <a href="https://www.coingecko.com/en/coins/uxd-protocol-token" target="_blank" rel="noreferrer" className="link">
              UXP on CoinGecko
            </a>
          </div>

          <div className="vesting">
            <div className="streamflow">
              <img src={streamflowImg} alt="streamflow" />
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="copyright">© UXD Protocol 2023</div>
        <a href="https://terms.uxd.fi/" target="_blank" rel="noreferrer" className="term-of-use">
          Terms of Use
        </a>
      </div>
    </div>
  );
}
