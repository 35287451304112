import multicoinImg from "../assets/images/investors/multicoin-capital.svg";
import defianceCapitalImg from "../assets/images/investors/defiance-capital.svg";
import cmsImg from "../assets/images/investors/cms.svg";
import solanaImg from "../assets/images/investors/solana.svg";
import meteoraImg from "../assets/images/investors/meteora.png";
import anatolyYakovenkoImg from "../assets/images/investors/anatoly-yakovenko.svg";
import rajGokalImg from "../assets/images/investors/raj-gokal.svg";
import cubeImg from "../assets/images/Cube.png";
import useWindowSize from "../hooks/useWindowSize";
import useIsMobile from "../hooks/useIsMobile";

import "./Part4.scss";

export default function Part4({ className }: { className?: string }) {
  const { width } = useWindowSize();
  const isMobile = useIsMobile();

  return (
    <div className={`Part4 ${className}`}>
      {width ? (
        <img
          src={cubeImg}
          alt="cube"
          className="cube"
          style={{
            left: width <= 1440 ? 0 : -((width - 1440) / 2),
          }}
        />
      ) : null}

      <div className={`title ${width && width <= 650 ? "title-mobile" : ""}`}>
        {isMobile ? (
          <>
            <div className="japanese">. 投資家 .</div>
            <div className="title-text">OUR INVESTORS</div>
          </>
        ) : (
          <>
            <img src="polygon-icon.svg" alt="polygon icon" />
            <div className="title-text">OUR INVESTORS</div> <div className="japanese">. 投資家 .</div>
          </>
        )}
      </div>

      <div className="body">
        <div className="text">
          {isMobile ? "" : "["} We are proudly supported by a strong group of investors. For more,{" "}
          <a href="mailto: info@uxd.fi" className="reach-out">
            reach out here
          </a>
          . {isMobile ? "" : "]"}
        </div>

        <div className="investors">
          <a href="https://multicoin.capital/" target="_blank" rel="noreferrer">
            <img className="investor" src={multicoinImg} alt="multicoin capital" />
          </a>
          <a href="https://defiance.capital/" target="_blank" rel="noreferrer">
            <img className="investor" src={defianceCapitalImg} alt="defiance capital" />
          </a>
          <a href="http://cmsholdings.io/" target="_blank" rel="noreferrer">
            <img className="investor" src={cmsImg} alt="cms" />
          </a>
          <a href="https://solana.ventures/" target="_blank" rel="noreferrer">
            <img className="investor" src={solanaImg} alt="solana" />
          </a>
          <a href="https://www.meteora.ag/" target="_blank" rel="noreferrer">
            <img className="investor" src={meteoraImg} alt="mercurial finance" />
          </a>
          <a href="https://twitter.com/aeyakovenko" target="_blank" rel="noreferrer">
            <img className="investor" src={anatolyYakovenkoImg} alt="anatoly yakovenko" />
          </a>
          <a href="https://twitter.com/rajgokal" target="_blank" rel="noreferrer">
            <img className="investor" src={rajGokalImg} alt="raj gokal" />
          </a>
        </div>
      </div>
    </div>
  );
}
