import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./index.scss";

ReactDOM.render(
  <React.StrictMode>
    <head>
      <link rel="stylesheet" href="https://use.typekit.net/syz8evy.css"></link>
    </head>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
