import { useEffect, useState } from "react";
import useWindowSize from "../hooks/useWindowSize";
import useIsMobile from "../hooks/useIsMobile";
import "./Part5.scss";

let timeout: NodeJS.Timeout | null = null;

export default function Part5({ className }: { className?: string }) {
  const { width } = useWindowSize();
  const isMobile = useIsMobile();
  const [left, setLeft] = useState<number>(0);

  // Makes the partners to move
  useEffect(() => {
    if (!width) return;

    timeout = setTimeout(() => {
      if (left <= -width) {
        setLeft(0);
      } else {
        setLeft(left - 1);
      }
    }, 50);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    };
  }, [left, width]);

  // Reset the partners position when the window is resized
  useEffect(() => {
    setLeft(0);
  }, [width]);

  return (
    <div className={`Part5 ${className}`}>
      <div className={`title ${width && width <= 650 ? "title-mobile" : ""}`}>
        {isMobile ? (
          <>
            <div className="japanese">· パートナー ·</div>
            <div className="title-text">OUR PARTNERS</div>
          </>
        ) : (
          <>
            <img src="polygon-icon.svg" alt="polygon icon" />
            <div className="title-text">OUR PARTNERS</div> <div className="japanese">· パートナー ·</div>
          </>
        )}
      </div>

      {width ? (
        <div
          className="partners"
          style={{
            width: width ?? 0,
            left: width < 1440 ? 0 : -((width - 1440) / 2),
          }}
        >
          <div
            className="partners-img"
            style={{
              left,
              width: width * 2,
            }}
          ></div>
        </div>
      ) : null}

      <div className="text">
        {isMobile ? "" : "["} We are working with industry leading protocols together to build a decentralized world. Interested?{" "}
        <a href="mailto: info@uxd.fi" className="email">
          Send us an email
        </a>{" "}
        or find us on Discord. {isMobile ? "" : "]"}
      </div>
    </div>
  );
}
