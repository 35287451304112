import { useEffect, useState } from "react";
import useWindowSize from "../hooks/useWindowSize";
import useScroll from "../hooks/useScroll";
import ImgALM from "../assets/images/ALM.png";

import "./Part2.scss";

export default function Part2({ className }: { className?: string }) {
  const [zoom, setZoom] = useState<boolean>(false);
  const { width } = useWindowSize();
  const y = useScroll();

  // cancel zoom if user scrolls
  useEffect(() => {
    if (zoom) {
      setZoom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [y]);

  return (
    <div className={`Part2 ${className}`}>
      <div className="title">ASSET LIABILITY MANAGEMENT</div>

      <div
        className="alm_picture_container"
        style={{
          justifyContent: width && width <= 1000 ? "flex-start" : "center",
        }}
      >
        {width && width <= 1000 ? (
          // No zoom before 1OOOpx
          <img
            className="alm_picture"
            src={ImgALM}
            alt="ALM presentation"
            style={{
              minHeight: "420px",
            }}
          />
        ) : (
          <img
            className="alm_picture"
            style={{
              cursor: zoom ? "zoom-out" : "zoom-in",
              width: zoom ? (width ? width - 150 : "95%") : "90%",
              position: zoom ? "fixed" : "relative",
              top: zoom ? "75px" : "0px",
              left: zoom ? "75px" : "0px",
              maxWidth: zoom ? "initial" : "70em",
            }}
            src={ImgALM}
            alt="ALM presentation"
            onClick={() => {
              setZoom(!zoom);
            }}
          />
        )}
      </div>

      {zoom ? (
        <div
          className="overlay_alm-picture-zoom"
          onClick={() => {
            setZoom(false);
          }}
        />
      ) : null}

      <div className="bullet_points">
        <div className="bullet_point">
          <div className="number">1</div>
          <div className="text">User deposits crypto assets, receives UXD Stablecoin in exchange</div>
        </div>

        <div className="bullet_point">
          <div className="number">2</div>
          <div className="text">UXD Protocol deploys crypto assets in low volatility strategies to generate yield</div>
        </div>

        <div className="bullet_point">
          <div className="number">3</div>
          <div className="text">Yield is distributed to UXP Stakers</div>
        </div>

        <div className="bullet_point">
          <div className="number">4</div>
          <div className="text">User can redeem UXD for crypto assets at par value anytime</div>
        </div>
      </div>
    </div>
  );
}
